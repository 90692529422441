.map-container {
    position: relative;
    width: 100vw;
    height: 600px;
    background: #f8f9fa;
    right: 200px;
}

.leaflet-container {
    background: #f8f9fa !important;
}

.leaflet-tile-pane {
    opacity: 0.6;
}

.leaflet-control-zoom {
    border: none !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
}

.leaflet-control-zoom a {
    background: white !important;
    border: 1px solid #E5E7EB !important;
    color: #1a1a1a !important;
}

.location-marker {
    position: relative;
     
}

.ripple-container {
    position: relative;
    width: 200px;
    height: 200px;
}

.ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    background: rgba(71, 87, 217, 0.08);
    border-radius: 50%;
    animation: ripple 2.5s infinite cubic-bezier(0.65, 0, 0.34, 1);
}

.marker-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background: #4757D9;
    border-radius: 50%;
    z-index: 2;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

@keyframes ripple {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

.ripple:nth-child(1) { animation-delay: 0s; }
.ripple:nth-child(2) { animation-delay: 0.8s; }
.ripple:nth-child(3) { animation-delay: 1.6s; }

.property-card {
    position: absolute;
    background: white;
    padding: 0;
    border-radius: 16px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.05);
    width: 350px;
    
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
    animation: cardAppear 0.3s forwards;
}

@keyframes cardAppear {
    to {
        opacity: 1;
        transform: translateY(0) translateX(-50%);
    }
}
.property-type {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 14px;
}

.property-value {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 500;
}

.view-all-button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 24px;
    background: white;
    border: 1px solid #E5E7EB;
    color: #1a1a1a;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    z-index: 1000;
    transition: all 0.2s ease;
}

.view-all-button:hover {
    background: #f8f9fa;
    border-color: #D1D5DB;
}