.header-nav-items{
  font-weight: 500;
  color:var(--color-primary) !important;
}
.guesthom-sub-heading-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  color: var(--color-steel);
  font-size: var(--font-size-section-text);
 
  h6{
    font-family: "Satoshi";
    font-weight: 500;
  }
}
.guesthom-section-title {
  color: #22252e;
  padding-bottom: 48px;
  font-size: var(--font-size-section-title);
  &.no-padding {
    padding-bottom: 0;
  }
}
.recent-blogs {
  padding-bottom: 190px;
  background: rgb(218, 255, 230);
  background: linear-gradient(
    148deg,
    rgba(218, 255, 230, 1) 0%,
    rgba(218, 255, 230, 1) 33%,
    rgba(200, 226, 255, 1) 100%
  );
  .title-wrapper {
    padding-top: 80px;
    .outer-div {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    position: relative;
    z-index: 2;
  }
  .grid-graphic {
    position: absolute;
    right: -70px;
    top: 40px;
    z-index: 1;
    @media screen and (max-width: 1530px) {
      right: 0;
    }
  }
}
.blog-cards-wrapper {
  position: relative;
  top: -138px;
}
.blog-body {
  .blog-row {
    gap: 32px;
    flex-wrap: wrap;
    .blog-card {
      width: 31.6%;
      text-align: left;
      .img-block {
        height: 240px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
      .content-block {
        padding-top: 32px;
        border-radius: 8px;
        font-family: "Satoshi";
        font-weight: 500;

        .title {
          font-size: var(--font-size-card-title);
          color: var(--color-main-blue);
          padding-bottom: 8px;
          line-height: 36px;
          font-family: "Nohemi";
          font-weight: 400;
        }
        .date {
          font-size: var(--font-size-card-sub-text);
          color: var(--color-steel);
          padding-bottom: 12px;
        }
        .description {
          font-size: var(--font-size-card-body);
          color: var(--color-steel);
          line-height: 24px;
        }
      }
      .action-btn {
        padding-top: 24px;
      }
    }
  }
  .slider-controls-wrapper{
    text-align: center;
    margin: auto;
    padding: 20px 30px;
    max-width: 100%;
    width: 366px;
    margin-top: 48px;
   
    .controls-row{
        display: flex;
        justify-content: space-between;
        color:var(--color-text);
        .prev-btn, .next-btn{
            cursor: pointer;
            &.disabled{
                opacity: 0.5;
                pointer-events: none;
            }
        }
        .rect-line{
            width: 32px;
            height: 2px;
            display: inline-block;
            vertical-align: middle;
            background-color: var(--color-text);
            padding: 0 13px;
            position: relative;
            top:-1px;
            margin: 0 13px;
        }
    }
}
  @media screen and (max-width: 860px) {
    .blog-row {
      flex-direction: column;
      .blog-card {
        width: 100%;
      }
    }
  }
}
.popular-searches {
  padding: 60px 0;
  background-color: var(--color-mint-faded);
  .guesthom-sub-heading-wrapper {
    justify-content: center;
    padding-bottom: 40px;
  }
  .search-result-body {
    .d-flex {
      gap: 20px;
      justify-content: space-between;
      .result-column {
        text-align: left;
        p {
          padding: 0 0 12px 0;
          font-size: var(--font-body);
          color: var(--color-main-blue);
          font-family: "Satoshi";
          font-weight: 500;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .search-result-body {
      .d-flex {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        .result-column {
          min-width: 230px;
        }
      }
    }
  }
  @media screen and (max-width: 720px) {
    .search-result-body {
      .d-flex {
        justify-content: center;

        .result-column {
          min-width: 100%;
          text-align: center;
        }
      }
    }
  }
}
.site-footer {
  background-color: var(--color-main-blue);
  padding: 60px 0;
  .footer-container {
    display: flex;
    justify-content: space-between;
    gap: 128px;
    @media screen and (max-width: 1160px) {
      gap: 60px;
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      .form-card {
        width: 100%;
        max-width: 100%;
      }
      .nav-links-block {
        width: 100%;
        .nav-link-list .left-column {
          max-width: 100%;
        }
      }
    }
  }
  .form-card {
    background-color: #000e2d;
    border-radius: 16px;
    padding: 32px 24px;
    text-align: left;

    max-width: 392px;
    @media screen and (min-width: 460px) {
      min-width: 392px;
    }
    .small-heading {
      color: var(--color-mint-green);
      padding-bottom: 20px;
    }
    .form-title {
      font-size: var(--font-size-28);
      color: var(--color-white);
      padding-bottom: 24px;
    }
    form {
      .footer-input {
        padding: 16px 0;
        width: 100%;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid var((--color-steel));
        background-color: transparent;
        color: var(--color-white);
        font-size: var(--font-body);
        margin-bottom: 16px;
        &::placeholder {
          color: var(--color-steel);
        }
      }
      .footer-btn {
        padding: 8px 16px;
        border-radius: 8px;
        border: none;
        background-color: var(--color-mint-green);
        color: var(--color-white);
        font-size: var(--font-body);
        cursor: pointer;
      }
    }
    .privacy-text {
      padding-top: 86px;
      font-size: 12px;
      color: var(--color-white);
      display: inline-block;
    }
  }
  .nav-links-block {
    .nav-link-list {
      display: flex;
      gap: 32px;
      padding-top: 32px;
      flex-wrap: wrap;

      .left-column {
        max-width: 435px;
        .link-list-row {
          flex-wrap: wrap;
          gap: 64px 0;
          .link-list {
            min-width: 178px;
            text-align: left;
            .small-heading {
              font-size: var(--font-size-14);
              color: var(--color-mint-green);
              padding: 0 0 24px 0;
            }
            .nav-title {
              color: var(--color-white);
              padding: 0 0 5px 0;
              font-size: var(--font-size-14);
              cursor: pointer;
            }
          }
        }
      }
    }
    .footer-bottom-row {
      padding-top: 115px;
      justify-content: space-between;
      align-items: center;
      .social-media-wrapper {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        .social-icon {
          background-color: #000e2d;
          border-radius: 100%;
          padding: 12px;
          cursor: pointer;
        }
      }
      .copy-right-text {
        font-size: 12px;
        color: var(--color-steel);
      }
    }
  }
}

.guesthom-slider-section {
  padding: 0 0 100px 0;
  img {
    filter: grayscale(100%);
  }
}
.map-section {
  margin-bottom: 80px;
  h2 {
    padding-bottom: 40px;
  }
  h6 {
    font-size: 20px;
    line-height: 28px;
    padding: 16px;
    margin: 0;
    text-align: left;
  }
  .map-propery-row {
    gap: 20px;
    padding: 16px;
    &.first-child {
      background-color: #f4faf6;
    }
  }
  .property-info {
    display: block;
    text-align: left;
    padding-bottom: 4px;
  }
  .property-card {
    z-index: 1000;
  }
}
.map-wrapper {
  height: 500px;
  width: 100%;
  position: relative;

  .leaflet-container {
    height: 100%;
    width: 100%;
  }
}
.location-card-wrapper {
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  width: 200px;
  text-align: left;

  h3 {
    font-size: 16px;
    margin: 0;
    color: #032850;
  }

  .price {
    font-size: 14px;
    margin: 5px 0;
    color: #000;
  }

  .properties {
    font-size: 12px;
    color: #666;
  }

  .highlight {
    color: var(--color-green, #28a745);
  }
}




