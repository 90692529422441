.guesthom-property-details-wrapper {
  text-align: left;
  .property-detail-hero-section {
    background: linear-gradient(
      45deg,
      rgba(218, 255, 230, 0.3) 0%,
      rgba(200, 226, 255, 0.3) 100%
    );
    padding: 60px 0 80px;
    .title-area {
      .read-detail {
        display: flex;
        align-items: center;
        gap: 12px;
        .breadcrumbs {
          color: var(--color-steel);
          font-weight: 500;
        }
      }
      h2 {
        font-size: 40px;
        padding-top: 20px;
      }
    }
  }
  .overview-image-section {
    margin-top: -55px;
    .overview-img-block {
    }
  }
  .property-detail-section {
    .d-flex {
      &.property-detail-section-row{
        gap:32px;
      }
      .left-column {
        width: calc(100% - 388px);
        .property-overview-wrapper {
          padding-top: 34px;
          .details {
            padding-bottom: 48px;
          }
          .details-row {
            display: flex;
            justify-content: space-between;
            h4 {
              color: var(--color-heading);
              font-size: 28px;
              font-family: "Nohemi";
              padding-bottom: 4px;
              line-height: 40px;
            }
            .text-row {
              display: flex;
              gap: 4px;
              span {
                color: var(--color-steel);
                font-weight: 500;
              }
            }
          }
          .sub-text {
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--color-green);
          }
          .overview {
            padding-bottom: 48px;
            .amenities-overview {
              display: flex;
              padding-top: 8px;

              .feature-block {
                border-right: 1px solid #d0d8df;
                padding: 0 41px;
                text-align: center;
                svg {
                  margin: auto;
                }
                &:last-child {
                  border-right: 0;
                }
              }
              .feature-icon {
                padding-bottom: 12px;
              }
              .feature,
              .count {
                font-size: 16px;
                font-weight: 500;
                padding-bottom: 4px;
              }
            }
          }
          .property-description-overview {
            padding-bottom: 48px;
            .description {
              p {
                color: var(--color-steel);
                font-weight: 500;
              }
            }
          }

          .property-information {
            padding-bottom: 48px;
            .info-table-wrapper {
              .d-flex {
                justify-content: space-between;
                gap: 60px;
                .left-col {
                  flex: auto;
                }
                .right-col {
                  flex: auto;
                }
                .key-value-pair {
                  display: flex;
                  border-bottom: 1px solid var(--color-divider);
                  padding: 10px 0;
                  gap: 20px;
                  .key-block {
                    min-width: 120px;
                    color: var(--color-steel);
                    font-weight: 500;
                  }
                  .value-block {
                    color: var(--color-primary);
                    font-weight: 500;
                  }
                }
              }
            }
          }
          .floor-plans {
            padding-bottom: 48px;
            .btns-row {
              display: flex;
              gap: 8px;
              margin-bottom: 20px;
              button {
                padding: 8px 16px;
                background-color: rgba(3, 40, 80, 0.05);
                border-radius: 8px;
                color: var(--color-primary);
              }
              button.active {
                background-color: var(--color-primary);
                color: var(--color-white);
              }
            }
            .plan-img-row {
              gap: 10px;
            }
          }
          .amenities-wrapper {
            padding-bottom: 48px;
            .features-row {
              display: flex;
              .f-block {
                background-color: var(--color-mint-faded);
                width: 160px;
                height: 132px;
                padding: 12px;
                text-align: center;
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 8px;

                &:nth-child(even) {
                  background-color: var(--color-pilot-faded);
                }
              }
            }
          }
          .graph-area {
            .graph-info-block {
              margin-bottom: 20px;
              color: var(--color-steel);
              font-weight: 500;
              .small-text {
                font-size: 12px;
              }
            }
            .graph-block {
              .row-graph {
                .avg-price-graph {
                  background-color: rgba(230, 233, 237, 0.3);
                  padding: 16px;
                  border-radius: 10px;
                }
                .popular-locations {
                  flex: auto;
                  background-color: rgba(230, 233, 237, 0.3);
                  padding: 16px;
                  border-radius: 10px;
                  table {
                    width: 100%;
                    td {
                      padding: 10px 0;
                      border-bottom: 1px solid #e1e2e4;
                      color: var(--color-steel);
                    }
                    th {
                      color: var(--color-steel);
                      font-weight: 500;
                    }
                  }
                }
              }
              .price-trend {
                padding: 16px;
                border-radius: 10px;
                background-color: var(--color-mint-faded);
                margin-bottom: 12px;
              }
              ul {
                list-style: disc;
                margin: 12px 0 48px;
                padding-left: 20px;
                li {
                  color: var(--color-steel);
                }
              }
            }
          }
          .nearby-similar-homes {
            padding-bottom: 60px;
            .cards-area{
              .home-row{
                gap:16px;
              }
            }
            .card-body{
              padding: 8px;
              border:1px solid var(--color-divider);
              border-radius: 10px;
              .image-block{
                margin-bottom: 12px;
                img{
                  width: 100%;
                  height: 128px;
                  object-fit: none; 
                  object-position: center; 
                  border-radius: 10px;
                }
              }
              .card-title{
                font-weight: 500;
              }
            }
            .home-card-row{
              align-items: center;
              justify-content: space-between;
            }
            .green-text{
              color:var(--color-green)
            }
            .card-title {
              font-size: var(--font-size-28);
              span {
                font-size: var(--font-size-20);
              }
            }
            .sub-text {
              font-size: var(--font-size-card-sub-text);
              color: var(--color-green);
              gap: 4px;
              display: flex;
              font-weight: 500;
            }

            .amenitites {
              padding: 16px 0 8px 0;
              display: flex;
              gap: 4px;
              flex-wrap: wrap;
              .amenity {
                background-color: #F7F7F7;
                font-size: 14px;
                padding: 4px 6px;
                display: flex;
                align-items: center;
                gap: 4px;
                border-radius: 8px;
                color: var(--color-steel);
                font-weight: 500;
                .icon{
                  svg{
                    path{
                      stroke: var(--color-steel);
                    }
                  }
                }
              }
            }
            .card-text {
              font-size: 16px;
              padding: 0 0 8px 0;
              display: flex;
              gap: 8px;
              align-items: center;
            }
          }
        }
      }
      .right-column {
        width: 360px;
        .schedule-tour-container{
          margin-top: 20px;
          h2{
            font-size: 20px;
            font-family: "Satoshi";
            font-weight: 500;
          }
          .bg-gray-100{
            background-color: var(--color-white);
            border:1px solid var(--color-divider);
            border-radius: 8px;
           }
          .active-btn{
            background-color: var(--color-primary);
            color: var(--color-white);
          }
        }
      }
    }
  }
}
